import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class NotifierService {

  constructor(private toastrService: NbToastrService) { }

  /**
   * Open a Toastr
   * 
   * @param message Message to show into the Toastr
   * @param status ("primary" | "success" | "warning" | "danger" | "info")
   * @param duration Milliseconds before the toastr disappears
   */
  
  show(message: any, title = '', status: any = environment.toastr.defaultStatus, duration: number = environment.toastr.defaultTimeout) {
    this.toastrService.show(message, title, { status, duration });
  }

}